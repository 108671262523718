<template>
  <div class="list-page">
    <el-button class="top-btn" size="small" type="primary" @click="handleAdd"
      >新增</el-button
    >
    <list-filter v-model="filterForm" :uploadFilter="ok" />
    <list-tabs
      class="table-tab"
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <el-button size="small" type="danger" @click="handleDel"
          >删除</el-button
        >
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button size="small" type="primary" @click="handleReduct"
          >还原</el-button
        >
        <el-button size="small" type="danger" @click="handleDel"
          >删除</el-button
        >
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选 -->
        <el-table-column
          class="select-check"
          fixed
          type="selection"
          width="55"
        ></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab == 'deleted'">
              <el-button type="text" size="small" @click="ReductRow(scope.row)">
                还原
              </el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row)"
                >删除</el-button
              >
            </el-button-group>
            <el-button-group v-if="filterForm.tab == 'normal'">
              <el-button type="text" size="small" @click="editRow(scope.row)">
                编辑
              </el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row)"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :sortable="th.sortable"
            :key="index"
          >
            <template slot-scope="scope">
              <!-- 复选框 -->
              <el-checkbox
                v-if="th.prop === 'is_show'"
                v-model="tbody[scope.$index][th.prop]"
                @change="changeStatus(scope.row, th.prop)"
              ></el-checkbox>
              <!-- 排序输入框 -->
              <PopoverInputEdit
                v-else-if="th.prop === 'sort'"
                input-type="number"
                :validator="validator"
                v-model="scope.row[th.prop]"
                @confirm="changeStatus(scope.row, th.prop)"
              >
              </PopoverInputEdit>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
  </div>
</template>

<script>
import listFilter from "../components/ListFilter";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import {
  journalList,
  updateSort,
  softDelete,
  Delete,
  putBack,
} from "../api/journal-list";
export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 90 },
        { label: "会刊名称", prop: "name", minWidth: 110 },
        { label: "会刊描述", prop: "desc", minWidth: 150 },
        {
          label: "年份",
          prop: "year",
          minWidth: 100,
        },
        {
          label: "内容类型",
          prop: "type_text",
          minWidth: 110,
        },
        {
          label: "图片数量",
          prop: "image_count",
          minWidth: 100,
          sortable: true,
        },
        {
          label: "浏览量",
          prop: "view_count",
          minWidth: 100,
          sortable: true,
        },
        { label: "排序", prop: "sort", minWidth: 100 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        name: "", //会刊名称
        year: "", //年份
        tab: "normal", //当前筛选tab
        page_size: 15, //每页数据量
      },
      //筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
    };
  },
  methods: {
    // 获取精神列表
    getJournalList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      journalList(pageData)
        .then((res) => {
          const { data } = res;
          data.data.forEach((item) => {
            item.is_show = item.is_show ? true : false;
          });
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //新增
    handleAdd() {
      // let routerData = this.$router.resolve({
      //   name: "AddJournal",
      //   params: { id: 0, type: "add" },
      // });
      // window.open(routerData.href, "_blank");
      this.$router.push({
        name: "AddJournal",
        params: { id: 0, type: "add" },
      });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      console.log(selection);
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === "normal") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "AddJournal",
        params: { id: row.id, type: "edit" },
      });
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          name: "", //会刊名称
          year: "", //年份
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getJournalList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getJournalList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getJournalList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getJournalList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      if (key === "sort") {
        data[key] = e[key];
      } else {
        data[key] = e[key] ? 1 : 0;
      }
      this.loading = true;
      // if (key === "is_show") {
      //   // 更新显示状态
      //   updateShow(data)
      //     .then((res) => {
      //       this.$message.success(res.msg);
      //       this.reflashList();
      //     })
      //     .catch(() => {
      //       this.refreshList();
      //     });
      // } else {
      // 更新排序状态
      updateSort(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch(() => {
          this.refreshList();
        });
      // }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getJournalList(pageData);
    },
  },
  created() {
    if (this.$route.params.tab) {
      this.filterForm.tab = this.$route.params.tab;
    }
    this.getJournalList(this.filterForm); //获取列表数据
  },
  components: { listFilter, Pagination, PopoverInputEdit },
};
</script>

<style lang="scss" scoped></style>
